function buildCountdown(wrapper,endDate = "2026-01-01T00:00:00") {
    let days, hours, minutes, seconds;

    endDate = new Date(endDate).getTime();

    if (isNaN(endDate)) {
        return;
    }

    setInterval(calculate, 1000);

    function calculate() {
        let startDate = new Date().getTime();

        let timeRemaining = parseInt((endDate - startDate) / 1000);

        if (timeRemaining >= 0) {
            days = parseInt(timeRemaining / 86400);
            timeRemaining = (timeRemaining % 86400);

            hours = parseInt(timeRemaining / 3600);
            timeRemaining = (timeRemaining % 3600);

            minutes = parseInt(timeRemaining / 60);
            seconds = parseInt(timeRemaining % 60);

            let daysWrapper = wrapper.querySelector(".js-countdown-days");
            let hoursWrapper = wrapper.querySelector(".js-countdown-hours");
            let minutesWrapper = wrapper.querySelector(".js-countdown-minutes");
            let secondsWrapper = wrapper.querySelector(".js-countdown-seconds");

            if(daysWrapper){
                daysWrapper.innerHTML = parseInt(days, 10);
            }

            if(hoursWrapper){
                hoursWrapper.innerHTML = ("0" + hours).slice(-2);
            }

            if(minutesWrapper){
                minutesWrapper.innerHTML = ("0" + minutes).slice(-2);
            }

            if(secondsWrapper){
                secondsWrapper.innerHTML = ("0" + seconds).slice(-2);
            }
        } else {
            return;
        }
    }
}

export function initCountdowns(query = document.querySelectorAll(".js-countdown")){
    for(let countdown of query){
        buildCountdown(countdown,countdown.dataset.endDate);
    }
}