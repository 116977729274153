import Swiper from 'swiper';
import {Autoplay, Navigation} from 'swiper/modules';

export function initEventSlider(query = document.querySelectorAll('.eventList')) {
    for (let eventList of query) {
        let eventslider = eventList.querySelector(".swiper");
        if(eventslider){
            let childrenCount = eventslider.querySelectorAll('.swiper-wrapper > *').length;
            let loop = false;

            let options = {
                autoplay_interval: 5000,
                eff_interval: 300
            };

            let breakpoints = {
                xs: {
                    items: 1,
                    autoplay: childrenCount > 1,
                },
                sm: {
                    items: 3,
                    autoplay: childrenCount > 3,
                },
                md: {
                    items: 3,
                    autoplay: childrenCount > 3,
                }
            };

            let gridBreakpoints = {};

            gridBreakpoints.sm = 768;
            gridBreakpoints.md = 1024;

            if (window.outerWidth > gridBreakpoints.sm && childrenCount > breakpoints.sm.items) {
                loop = true;
            }

            if (window.outerWidth > gridBreakpoints.md && childrenCount > breakpoints.md.items) {
                loop = true;
            }

            Swiper.use([Autoplay, Navigation]);
            new Swiper(eventslider, {
                loop: loop,
                speed: options.eff_interval,
                initialSlide: 0,
                spaceBetween: 60,
                navigation: {
                    nextEl: eventList.querySelector('.swiper-button-next'),
                    prevEl: eventList.querySelector('.swiper-button-prev'),
                },
                breakpoints: {
                    0: {
                        slidesPerView: breakpoints.xs.items,
                        spaceBetween: 30,
                        autoplay: {
                            enabled: breakpoints.xs.autoplay
                        }
                    },
                    768: {
                        slidesPerView: breakpoints.sm.items,
                        autoplay: {
                            enabled: breakpoints.sm.autoplay
                        }
                    },
                    1024: {
                        slidesPerView: breakpoints.md.items,
                        spaceBetween: 60,
                        autoplay: {
                            enabled: breakpoints.md.autoplay
                        }
                    }
                }
            });
        }
    }
}
